/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "styles/primeng-table";
@import "styles/primeng-panel";
@import "styles/primeng-message";
@import "styles/primeng-multiselect";
@import "~handsontable/dist/handsontable.full.css";

:root {
  --master-row-color: #badade !important;
  --subquote-row-color: #e1e1e1 !important;
  --line-item-row-color: #fffbe1 !important;
}

html {
  font-size: 14px;
}

.default-width {
  min-width: 120px;
}

.vp-modal-xl {
  max-width: 1300px;
}

.vp-btn-icon {
  width: 17px;
}

.alert {
  margin: 0;
}

.handsontable td.ht-cell-error {
  position: relative;
}

.handsontable td.ht-cell-error::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid red;
}

.handsontable td.ht-cell-error .htAutocompleteArrow {
  position: absolute;
  right: 4px;
}

@media screen and (max-width: 960px) {
  p-table {
    .p-datatable-wrapper {
      table {
        tbody {
          td {
            display: table-cell !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1px) {
  p-table {
    .p-datatable-wrapper {
      table {
        thead {
          .p-datatable-thead {
            tr {
              th {
                display: table-cell !important;
              }
            }
          }
        }
      }
    }
  }
}
