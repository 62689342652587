:root {
  --cell-padding: .5rem;
}

.p-datatable, .p-treetable {
  i.pi-sort-alt {
    display: none;
  }
}

.p-datatable-scrollable-body, .p-treetable-scrollable-body {
  height: 60vh;
  background-color: #f7f4f4;
}

.vp-table-auto-height {
  .p-datatable-scrollable-body, .p-treetable-scrollable-body {
    height: auto;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: var(--cell-padding);

  &.icon-button-cell {
    padding: .25em .5em;
  }
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: var(--cell-padding);

  .p-treetable-toggler {
    margin-top: -.25em;
    margin-bottom: -.25em;
  }
}

.p-datatable .p-datatable-thead > tr > th,
.p-treetable .p-treetable-thead > tr > th {
  padding: var(--cell-padding);
}

td.icon-button-cell button.p-button.p-button-icon-only {
  width: 2em;
  height: 2em;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-paginator {
  padding: 0;
}

.override-paginator-left .p-paginator-left-content {
  margin-right: unset;
}
